import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h2 className="pp-title">Privacy Policy</h2>
      <div>
        <p>
          <b>1. Introduction</b>
          <p>
            Welcome to Hoppyhour! We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we
            collect, use, and safeguard your information when you use our app.
          </p>
          <b>2. Information We Collect</b>
          <p>
            <ul>
              <li>
                Personal Information: When you create an account or use the app, we may collect personal information such as your name, email address,
                and payment details.
              </li>
              <li>
                Gift and Event Details: We collect information related to the gifts you send, events you plan, and any contributions made by guests.
              </li>
            </ul>
          </p>
          <b>3. How We Use Your Information</b>
          <p>
            <ul>
              <li>
                To Provide Services: We use your information to process transactions, send gifts, manage events, and facilitate communication between
                users.
              </li>
              <li>To Improve Our App: We use usage data to analyze app performance and improve our services.</li>
              <li>For Customer Support: We may use your information to respond to your inquiries and provide customer support.</li>
            </ul>
          </p>
          <b>4. Sharing Your Information</b>
          <p>
            <ul>
              <li>For Legal Reasons: We may disclose your information if required by law or to protect our rights and safety.</li>
            </ul>
          </p>
          <b>5. Security</b>
          <p>
            We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no
            method of transmission over the internet or electronic storage is completely secure.
          </p>
          <b>6. Your Choices</b>
          <p>
            <ul>
              <li>Access and Update: You can access and update your personal information through your account settings.</li>
            </ul>
          </p>
          <b>7. Changes to This Privacy Policy</b>
          <p>
            We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage
            you to review this Privacy Policy periodically.
          </p>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
