import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { addHotel, getCities } from "../../../../api";
import S3Bucket from "../../../../appConfig/aws-config";
import { s3BucketConstants } from "../../../../constants";
import useAlert from "../../../../hooks/alert-hook";
import useImageUploader from "../../../../hooks/image-uploader";
import { validateEmail, validateImage, validateName, validatePassword } from "../../../../validation/validator";

const useAddHotel = () => {
  const { selectedFile, preview, onSelectFile } = useImageUploader();
  const [hotelName, setHotelName] = useState("");
  const [address, setAddress] = useState("");
  const [hotelId, setHotelId] = useState("");
  const [city, setCity] = useState({ name: "", id: "" });
  const [province, setProvince] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [category, setCategory] = useState("BWRY");
  const { showAppAlert } = useAlert();

  const [loading, setLoading] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [secretCode, setSecretCode] = useState("");
  const [cityData, setCityData] = useState([]);
  const [formError, setFormError] = useState(false);

  const fetchCities = () => {
    const body = {
      pageNo: 0,
      pageSize: 1000,
    };
    getCities(body).then((res) => setCityData(res.content));
  };

  useEffect(() => {
    fetchCities();
  }, []);

  const history = useHistory();

  const onChangeHotelName = useCallback((e) => {
    setHotelName(e.target.value);
  }, []);

  const onChangeAddress = useCallback((e) => {
    setAddress(e.target.value);
  }, []);

  const onChangeHotelId = useCallback((e) => {
    setHotelId(e.target.value);
  }, []);

  const onChangeCity = useCallback((e) => {
    setCity(e.target.value);
  }, []);

  const onChangeProvince = useCallback((e) => {
    setProvince(e.target.value);
  }, []);

  const onChangePhoneNumber = useCallback((e) => {
    setPhoneNumber(e.target.value);
  }, []);

  const onChangePostalCode = useCallback((e) => {
    setPostalCode(e.target.value);
  }, []);

  const onChangeCategory = useCallback((e) => {
    setCategory(e);
  }, []);

  const onSelectCity = useCallback((val) => {
    setCity(val);
  }, []);

  const onChangeAdminEmail = useCallback((e) => {
    setAdminEmail(e.target.value);
  }, []);

  const onChangeAdminPassword = useCallback((e) => {
    setAdminPassword(e.target.value);
  }, []);

  const onChangeSecretCode = useCallback((e) => {
    setSecretCode(e.target.value);
  }, []);

  const validator = {
    adminEmail: validateEmail(adminEmail),
    adminPassword: validatePassword(adminPassword),
    hotelName: validateName(hotelName),
    address: validateName(address),
    city: validateName(city.name),
    province: validateName(province),
    phoneNumber: validateName(phoneNumber),
    postalCode: validateName(postalCode),
    secretCode: validateName(secretCode),
    hotelId: validateName(hotelId),
    image: validateImage(selectedFile),
  };

  const checkFormError = () => {
    setFormError(validator);
  };

  const onAddHotel = (fileName = "") => {
    const body = {
      name: hotelName,
      addressLine1: address,
      city: city.name,
      province,
      phone: phoneNumber,
      storeId: hotelId,
      image: fileName,
      postalcode: postalCode,
      category,
      adminEmail,
      adminPassword,
      secretCode,
    };

    // console.log("Add Hotel Request Body", body);

    addHotel(body)
      .then((res) => {
        if (res.httpStatus === "OK") {
          setLoading(false);
          showAppAlert("success", "Added successfully");
          history.push("hotels");
        }
      })
      .catch((err) => setLoading(false));
  };

  const uploadImage = () => {
    // console.log("selectedFile", selectedFile);
    const uniqueId =
      hotelName.replace(/[^a-zA-Z]/gm, "").slice(0, 5) + city.name.replace(/[^a-zA-Z]/gm, "").slice(0, 5) + hotelId.slice(0, 5) + Date.now();

    const fileName = `${s3BucketConstants.HOTEL_IMAGE_FOLDER}/${uniqueId}.${selectedFile.name.split(".").pop()}`;

    const params = {
      Body: selectedFile,
      Bucket: s3BucketConstants.BUCKET_NAME,
      Key: fileName,
      ACL: "public-read",
      ContentDisposition: "inline",
      ContentType: selectedFile.type,
    };
    try {
      S3Bucket.putObject(params)
        .on("httpUploadProgress", (evt) => {
          // console.log("Progess", Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err) => {
          if (err) {
            // console.log("Image upload failed", err);
            showAppAlert("error", "Image upload failed");
            setLoading(false);
          } else {
            onAddHotel(fileName);
          }
        });
    } catch (error) {
      setLoading(false);
      // console.log("Upload error", error);
      showAppAlert("error", "Image upload failed");
    }
  };

  const onSubmit = () => {
    checkFormError();
    const invalidForm = Object.values(validator).some((x) => x !== false);
    // console.log("FORM ERROR", formError);
    if (!invalidForm) {
      setLoading(true);
      uploadImage();
    }
  };

  return {
    formError,
    selectedFile,
    preview,
    hotelName,
    address,
    hotelId,
    province,
    phoneNumber,
    postalCode,
    category,
    loading,
    adminEmail,
    adminPassword,
    secretCode,
    cityData,
    onChangeAdminEmail,
    onChangeAdminPassword,
    onChangeSecretCode,
    onSelectFile,
    onChangeHotelName,
    onChangeAddress,
    onChangeHotelId,
    onChangeCity,
    onChangeProvince,
    onChangePhoneNumber,
    onChangePostalCode,
    onChangeCategory,
    onSelectCity,
    onSubmit,
  };
};

export default useAddHotel;
