import React from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ErrorHandlerProvider from "./context/errorhandlerContext";
import { AuthRoute, ProtectedRoute } from "./helpers/authHelper";
import ViewApp from "./views/app";
import ViewError from "./views/error";
import PrivacyPolicy from "./views/privacyPolicy";
import Support from "./views/support";
import ViewUnauthorized from "./views/unauthorized";
import LoginScreen from "./views/user/login";

// const ViewApp = React.lazy(() => import("./views/app"));
// const ViewError = React.lazy(() => import("./views/error"));
// const ViewUnauthorized = React.lazy(() => import("./views/unauthorized"));

const App = () => {
  return (
    <>
      <ErrorHandlerProvider>
        <Router>
          <Switch>
            <ProtectedRoute path="/app" component={ViewApp} />
            <Redirect exact from="/" to="/app" />
            <AuthRoute path="/user/login" component={LoginScreen} />
            <AuthRoute path="/privacy-policy" component={PrivacyPolicy} />
            <AuthRoute path="/support" component={Support} />

            <Route path="/error" exact render={(props) => <ViewError {...props} />} />
            <Route path="/unauthorized" exact render={(props) => <ViewUnauthorized {...props} />} />
            <Redirect to="/error" />
          </Switch>
        </Router>
      </ErrorHandlerProvider>
    </>
  );
};

export default App;
