import React from "react";

const Support = () => {
  return (
    <div className="support-container">
      <h1>Hoppyhour Support</h1>

      <p>If you need any assistance or have questions, we're here to help!</p>
      <p>
        Please reach out to us at:
        <a href="mailto:support@hoppyhourapp.ca"> support@hoppyhourapp.ca</a>
      </p>
      <p>We'll get back to you as soon as possible.</p>
    </div>
  );
};

export default Support;
